import { Button, C2A } from "@atoms/button/";
import { Loader } from "@atoms/loader/Loader";
import { FC } from "react";

export const DesktopSubmitButton: FC<{
  loading: boolean;
  label?: string;
  show?: boolean;
}> = ({ loading, label, show = true }) => (
  <Button
    type="submit"
    variant="tertiary"
    background="dark"
    disabled={loading}
    className={show ? "hidden border-none sm:flex xl:py-0" : "hidden"}
  >
    <C2A className={loading ? "hidden" : ""}>
      {loading ? "" : label || "Submit"}
    </C2A>
    {loading && <Loader className="size-4" />}
  </Button>
);
