import { Button, C2A } from "@atoms/button/";
import { routes } from "@utils/routes/mapping";
import Link from "next/link";
import { FC } from "react";

interface ContactButtonProps {
  darkTheme: boolean;
  buttonText: string;
  forceGreenColor?: boolean;
}

export const ContactButton: FC<ContactButtonProps> = ({
  darkTheme,
  buttonText,
  forceGreenColor,
}) => (
  <div className="relative block px-7 pb-9 max-lg:bg-white md:px-14 lg:hidden lg:px-0 lg:pb-0 lg:pt-6 xl:block">
    <Button
      asChild
      id="menu-cta"
      variant={forceGreenColor ? "primary" : "secondary"}
      size="small"
      background={darkTheme ? "dark" : "light"}
      className="flex"
    >
      <Link href={routes.contact()}>
        <C2A>{buttonText}</C2A>
      </Link>
    </Button>
  </div>
);
