export const menuAnimationVariants = {
  expand: {
    height: "auto",
    transition: { ease: "easeIn", duration: 0.2 },
  },
  collapse: {
    height: 0,
    transition: { ease: "easeIn", duration: 0.2 },
  },
};

export const dropdownAnimationVariants = {
  expand: {
    height: "auto",
    transition: { ease: "easeInOut", duration: 0.2 },
  },
  instantExpand: {
    height: "auto",
    transition: { duration: 0 },
  },
  collapse: {
    height: 0,
    transition: { ease: "easeInOut", duration: 0.2 },
  },
  instantCollapse: {
    height: 0,
    transition: { duration: 0 },
  },
};
