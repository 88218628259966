import { motion } from "motion/react";
import { FC, PropsWithChildren } from "react";
import { menuAnimationVariants } from "../shared/animations";

export type NavContentProps = PropsWithChildren<{
  isMobile: boolean;
  mobileOpen: boolean;
}>;

export const NavContent: FC<NavContentProps> = ({
  isMobile,
  mobileOpen,
  children,
}) =>
  isMobile ? (
    <motion.div
      variants={menuAnimationVariants}
      initial="collapse"
      animate={mobileOpen ? "expand" : "collapse"}
      className="overflow-hidden"
    >
      {children}
    </motion.div>
  ) : (
    <div className="hidden justify-between lg:flex">{children}</div>
  );
