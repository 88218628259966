import { NodeWithTwoSidedLayout } from "@layout/navigation/Nav.types";
import { cn } from "@utils/tailwind";
import { FC, Fragment } from "react";
import { BlackLink } from "../black-link/BlackLink";
import { FeaturedLink } from "../featured-link/FeaturedLink";
import { GreenLink } from "../green-link/GreenLink";

interface Props {
  node: NodeWithTwoSidedLayout;
}

export const DropdownColumnsAndRelated: FC<Props> = ({ node }) => {
  let prevBlackLink = false;
  return (
    <>
      <div className="flex basis-full xl:basis-7/12 2xl:basis-6/12">
        <div
          className="hidden w-[calc(125px+64px+16px)] lg:block"
          data-name="placeholder"
        />
        <div className="ml-8 w-full lg:ml-0 lg:pr-10 xl:w-[calc(100%-125px-64px-16px)] [@media(max-width:1279px)]:max-w-[666px]">
          <div className="hidden cursor-default text-nowrap border-b border-current pb-4 text-xs font-medium uppercase text-content-primary-on-light opacity-50 lg:block">
            {node.leftSide.name}
          </div>

          <div className="lg:grid lg:grid-cols-2 lg:gap-x-6">
            <ul role="menu">
              {node.leftSide.leftCol.map((column, idx) => (
                <Fragment key={idx}>
                  <li
                    role="presentation"
                    className={cn(
                      (column.children.length || prevBlackLink || idx === 0) &&
                        `lg:pt-6`
                    )}
                  >
                    <GreenLink link={column.link} />
                  </li>
                  {(prevBlackLink = false)}
                  {column.children.map((link, idx) => {
                    prevBlackLink = true;
                    return (
                      <li role="presentation" key={`j${idx}`}>
                        <BlackLink item={link} />
                      </li>
                    );
                  })}
                </Fragment>
              ))}
            </ul>
            {(prevBlackLink = false)}
            <ul role="menu">
              {node.leftSide.rightCol.map((column, idx) => (
                <Fragment key={idx}>
                  <li
                    key={`i${idx}`}
                    role="presentation"
                    className={cn(
                      `uppercase`,
                      (column.children.length || prevBlackLink || idx === 0) &&
                        `lg:pt-6`
                    )}
                  >
                    <GreenLink link={column.link} />
                  </li>
                  {(prevBlackLink = false)}
                  {column.children.map((link, idx) => {
                    prevBlackLink = true;
                    return (
                      <li role="presentation" key={`j${idx}`}>
                        <BlackLink item={link} />
                      </li>
                    );
                  })}
                </Fragment>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="relative hidden xl:block xl:basis-5/12 2xl:basis-6/12">
        <div className="max-w-[512px]">
          <div className="mb-6 text-nowrap border-b border-current pb-4 text-xs font-medium uppercase text-content-primary-on-light opacity-50">
            {node.rightSide.name}
          </div>
          <ul>
            {node.rightSide.children.map((article, idx) => (
              <FeaturedLink item={article} key={idx} />
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};
