import { Slot } from "@radix-ui/react-slot";
import { cn } from "@utils/tailwind";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

const buttonVariants = cva(
  `group/button
  inline-flex
  items-center
  justify-center
  gap-2
  whitespace-nowrap
  rounded-full
  border
  font-termina
  font-medium
  no-underline
  outline-none
  disabled:pointer-events-none
  disabled:opacity-40
  [&_svg]:pointer-events-none
  [&_svg]:shrink-0`,
  {
    variants: {
      variant: {
        primary:
          "border-green-accent-two bg-green-accent-two text-content-primary-on-light hover:border-green-accent-one hover:bg-green-accent-one focus:bg-green-accent-two",
        secondary:
          "bg-transparent hover:border-green-accent-one hover:bg-green-accent-one focus:border-green-accent-two focus:bg-transparent",
        tertiary:
          "border-transparent bg-transparent focus:border-transparent focus:text-green-accent-two",
      },
      background: {
        dark: "",
        light: "",
      },
      size: {
        default: "px-9 py-3 text-xs xl:px-12 xl:py-4 xl:text-base",
        small: "px-9 py-3 text-xs",
      },
    },
    compoundVariants: [
      {
        variant: "primary",
        background: "light",
        className: "focus:border-content-primary-on-light",
      },
      {
        variant: "primary",
        background: "dark",
        className: "focus:border-content-primary-on-dark",
      },
      {
        variant: "secondary",
        background: "light",
        className:
          "border-content-primary-on-light text-content-primary-on-light",
      },
      {
        variant: "secondary",
        background: "dark",
        className:
          "border-content-primary-on-dark text-content-primary-on-dark hover:text-content-primary-on-light focus:text-content-primary-on-dark",
      },
      {
        variant: "tertiary",
        background: "light",
        className: "text-content-primary-on-light",
      },
      {
        variant: "tertiary",
        background: "dark",
        className: "text-content-primary-on-dark",
      },
    ],
    defaultVariants: {
      variant: "primary",
      background: "light",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, variant, background, size, asChild = false, ...props },
    ref
  ) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, background, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
