import { cn } from "@utils/tailwind";
import { FC, PropsWithChildren } from "react";

export type NavigationContainerProps = PropsWithChildren<{
  darkMode: boolean;
  mobileOpen: boolean;
  isInitialPos: boolean;
  isAnyDropdownOpen: boolean;
}>;

export const NavigationContainer: FC<NavigationContainerProps> = ({
  darkMode,
  children,
  mobileOpen,
  isAnyDropdownOpen,
  isInitialPos,
}) => (
  <nav
    aria-label="Main Navigation"
    className={cn([
      "text-content-primary-on-light",
      "fixed inset-x-0 top-0 z-50 w-full",
      isInitialPos ? "" : "lg:bg-white",
      isAnyDropdownOpen ? "lg:bg-menu-light" : "",
      mobileOpen ? "h-full" : "",
      /* hack for the menu glitch: https://app.clickup.com/t/8697q2mdb */
      darkMode ? "bg-[rgba(0,0,0,0.009)]" : "bg-[rgba(255,255,255,0.009)]",
    ])}
  >
    <div
      className={cn([
        "block h-full transition-[padding-top] lg:container-flexible lg:flex lg:flex-nowrap",
        isInitialPos ? "lg:pt-[22px]" : "",
      ])}
    >
      {children}
    </div>
  </nav>
);
