import { DynamicPageCategory } from "@strapi/types";
import { joinPaths } from "@utils/urls/url-utils";

/**
 * It checks whether a page is greater than one because we don't want to build
 * routes like `/<listing>/page/1/` because we redirect these listings
 * to the `/<listing>/` pages
 * */
const isOkPage = (page?: number) => page && page > 1;

export const routes = {
  home: () => "/",
  notFound: () => "/not-found/",
  contact: () => "/contact/",
  thankYou: () => "/thank-you/",
  companyNews: (page?: number) =>
    joinPaths(["company-news", isOkPage(page) ? `/page/${page}` : undefined]),
  singleCompanyNews: (slug: string) => joinPaths(["company-news", slug]),
  search: () => "/search/",
  page: (slug: string) => `/${slug}/`,
  dynamicPage: (categorySlug: DynamicPageCategory, slug: string) =>
    joinPaths([categorySlug, slug]),
  dynamicPageListing: (category: DynamicPageCategory) => `/${category}/`,
  blog: (category: string, slug: string) => joinPaths(["blog", category, slug]),
  blogListing: (category?: string, page?: number, tag?: string) =>
    joinPaths([
      "blog",
      tag ? `tag/${tag}` : category,
      isOkPage(page) ? `/page/${page}` : undefined,
    ]),
  successStory: (slug: string) => joinPaths(["success-stories", slug]),
  successStoriesListing: (industry?: string, page?: number) =>
    joinPaths([
      "success-stories",
      industry ? `industry/${industry}` : undefined,
      isOkPage(page) ? `/page/${page}` : undefined,
    ]),
  feedsXml: () => "/feeds/blog/rss.xml",
  author: (slug: string) => joinPaths(["authors", slug]),
  authorsListing: (page?: number) =>
    joinPaths(["authors", isOkPage(page) ? `/page/${page}` : undefined]),
};
