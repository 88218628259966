import { cn } from "@utils/tailwind";
import { FC, HTMLAttributes } from "react";

export type BackdropProps = HTMLAttributes<HTMLDivElement> & {
  enabled: boolean;
};

export const Backdrop: FC<BackdropProps> = ({
  className,
  enabled,
  children,
  ...props
}) => (
  <div
    className={cn(
      "h-[calc(100%-78px)] lg:h-auto lg:grow",
      enabled && "overflow-y-auto backdrop-blur-sm backdrop-brightness-90",
      className
    )}
    {...props}
  >
    {children}
  </div>
);
