import { motion } from "motion/react";
import { FC } from "react";
import { cn, tw } from "utils/tailwind";

const transition = { duration: 0.3, ease: "easeInOut", times: [0, 0.5, 1] };
const step1 = "translateX(50%)";
const step2 = "translateX(50%) translateY(calc(7px * var(--direction)))";
const step3 =
  "translateX(50%) translateY(calc(7px * var(--direction))) rotate(calc(45deg * var(--direction)))";

const outerLinesVariants = {
  initial: { transform: step1 },
  open: { transform: [step1, step2, step3], transition },
  close: { transform: [step3, step2, step1], transition },
};

const innerLineVariants = {
  initial: { opacity: 100 },
  open: { opacity: [100, 0, 0], transition },
  close: { opacity: [0, 0, 100], transition },
};

const lineStyles = tw`absolute block h-0.5 w-full rounded-full bg-current`;

type TogglerProps = {
  id: string;
  opened: boolean;
  className?: string;
  onToggle: () => void;
};

export const NavToggler: FC<TogglerProps> = ({
  id,
  opened,
  className,
  onToggle,
}) => (
  <button
    id={id}
    className={cn(
      "relative block size-[32px] cursor-pointer border-none bg-transparent p-[8px] text-content-primary-on-light lg:hidden",
      className
    )}
    onClick={onToggle}
    aria-label="Open Menu"
    aria-expanded={opened ? "true" : "false"}
    aria-haspopup="true"
    aria-controls="menu"
  >
    <div className="relative size-full">
      <motion.span
        variants={outerLinesVariants}
        initial="initial"
        animate={opened ? "open" : "close"}
        className={cn(lineStyles, "-left-2/4 top-0 [--direction:1]")}
      />
      <motion.span
        variants={innerLineVariants}
        initial="initial"
        animate={opened ? "open" : "close"}
        className={cn(
          lineStyles,
          "top-1/2 -translate-y-1/2",
          opened && "opacity-0"
        )}
      />
      <motion.span
        variants={outerLinesVariants}
        initial="initial"
        animate={opened ? "open" : "close"}
        className={cn(
          lineStyles,
          "-left-2/4 bottom-0 translate-x-2/4 [--direction:-1]"
        )}
      />
    </div>
  </button>
);
