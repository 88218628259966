import { RootNode } from "@layout/navigation/Nav.types";
import { cn } from "@utils/tailwind";
import { motion } from "motion/react";
import { FC, ReactNode } from "react";
import { dropdownAnimationVariants } from "../shared/animations";
import styles from "./DropdownContainer.module.scss";

export type DropdownContainerProps = {
  node: RootNode;
  isOpen: boolean;
  isMobile: boolean;
  children?: ReactNode;
};

export const DropdownContainer: FC<DropdownContainerProps> = ({
  node,
  isOpen,
  isMobile,
  children,
}) => (
  <motion.div
    variants={dropdownAnimationVariants}
    initial="collapse"
    animate={isOpen ? (isMobile ? "expand" : "instantExpand") : "collapse"}
    className={cn(
      `w-full
      overflow-hidden
      bg-white
      transition-[top]
      lg:fixed
      lg:left-0
      lg:top-[--nav-height]
      lg:bg-menu-light`,
      !isOpen && "lg:hidden",
      node.layout === "two-sided" && styles.lightGray
    )}
  >
    <div className="relative flex flex-wrap border-b border-b-grey-scale-light-gray pb-5 lg:container-flexible group-last/mainlink:border-none lg:border-none lg:py-14">
      {children}
    </div>
  </motion.div>
);
