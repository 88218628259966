import { RootNode } from "@layout/navigation/Nav.types";
import { cn } from "@utils/tailwind";
import Link from "next/link";
import { FC, PropsWithChildren } from "react";
import styles from "./MainLink.module.scss";

export type MainLinkProps = PropsWithChildren<{
  node: RootNode;
  changeMenuColor: boolean;
  isInitialPos: boolean;
  darkMode: boolean;
  isChildDropdownOpen: boolean;
  isAnyDropdownOpen: boolean;
  onToggle?: () => void;
}>;

export const MainLink: FC<MainLinkProps> = ({
  node,
  changeMenuColor,
  isInitialPos,
  darkMode,
  isChildDropdownOpen,
  isAnyDropdownOpen,
  children,
  onToggle,
}) => (
  <li role="presentation" className="group/mainlink">
    <Link
      data-color={changeMenuColor ? "white" : "black"}
      href={node.link.href}
      role="menuitem"
      aria-expanded={isChildDropdownOpen}
      aria-haspopup="true"
      className={cn(
        `block
        border-b
        border-b-grey-scale-light-gray
        py-6
        text-lg
        font-medium
        uppercase
        transition-[padding,border-color]
        duration-200
        ease-in
        focus:outline-disabled
        group-last/mainlink:border-none
        aria-expanded:border-transparent
        max-lg:aria-expanded:pb-2
        md:py-7
        lg:border-none
        lg:px-4
        lg:py-5
        lg:text-sm`,
        styles.mainItem,
        isInitialPos && darkMode ? "lg:text-white" : "",
        isAnyDropdownOpen ? "lg:text-black" : ""
      )}
      onClick={(e) => {
        e.preventDefault();
        if (onToggle) onToggle();
      }}
    >
      <span>
        {node.link.name}
        <span />
      </span>
    </Link>
    {children}
  </li>
);
