import {
  DropdownData,
  NodeWithColumnsLayout,
  NodeWithNestedColumns,
} from "@layout/navigation/Nav.types";
import { cn } from "@utils/tailwind";
import { motion } from "motion/react";
import Link from "next/link";
import { FC, Fragment } from "react";
import { BlackLink } from "../black-link/BlackLink";
import { GreenLink } from "../green-link/GreenLink";
import { dropdownAnimationVariants } from "../shared/animations";
import styles from "./DropdownColumns.module.scss";

export type DropdownColumnsProps = {
  node: NodeWithColumnsLayout;
  isMobile: boolean;
  openDropdowns?: DropdownData[];
  onToggle?: (dropdownId: string) => void;
};

export const DropdownColumns: FC<DropdownColumnsProps> = ({
  node,
  isMobile,
  openDropdowns,
  onToggle,
}) => {
  const isExpanded = (column: NodeWithNestedColumns) =>
    !isMobile || openDropdowns?.some((it) => it.id === column.id);

  let prevBlackLink = false;
  return (
    <ul
      role="menu"
      className={cn(
        "block w-full lg:grid lg:w-auto lg:gap-x-20 lg:pl-[calc(125px+64px+16px)]",
        // 190px is a manually measured width of the longest link name.
        // If we don't limit width then the whole container
        // will be resized when hovering green links
        "lg:grid-cols-[repeat(3,190px)]"
      )}
    >
      {node.columns.map((column, idx) => (
        <li className="ml-6 block lg:ml-0" role="presentation" key={idx}>
          <Link
            role="menuitem"
            aria-expanded={isExpanded(column)}
            aria-haspopup="true"
            href={column.link.href}
            className={cn(
              styles.labelItem,
              "block focus:lg:outline-disabled lg:inline-block",
              "cursor-pointer  lg:cursor-default",
              "text-nowrap border-current text-lg font-medium uppercase lg:border-b lg:text-xs lg:text-content-primary-on-light lg:opacity-50",
              "py-3 lg:pb-4 lg:pt-0 "
            )}
            onClick={(e) => {
              e.preventDefault();
              if (onToggle) onToggle(column.id);
            }}
            tabIndex={isMobile ? undefined : -1}
          >
            <span>
              {column.link.name}
              <span />
            </span>
          </Link>
          <motion.div
            variants={dropdownAnimationVariants}
            initial="collapse"
            animate={isExpanded(column) ? "expand" : "collapse"}
            className="max-lg:overflow-hidden"
          >
            <ul
              role="menu"
              className="ml-5 text-lg lg:ml-0 lg:block lg:text-sm"
            >
              {column.children.map((innerColumn, idx) => (
                <Fragment key={idx}>
                  <li
                    role="presentation"
                    className={cn(
                      `block`,
                      (innerColumn.children.length ||
                        prevBlackLink ||
                        idx === 0) &&
                        `lg:pt-6`
                    )}
                  >
                    <GreenLink link={innerColumn.link} />
                  </li>
                  {(prevBlackLink = false)}
                  {innerColumn.children.map((link, idx) => {
                    prevBlackLink = true;
                    return (
                      <li role="presentation" key={`c${idx}`}>
                        <BlackLink item={link} />
                      </li>
                    );
                  })}
                </Fragment>
              ))}
            </ul>
          </motion.div>
        </li>
      ))}
    </ul>
  );
};
