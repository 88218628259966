import { routes } from "@utils/routes/mapping";
import { useRouter } from "next/navigation";
import { useEffect } from "react";
import { DropdownData, Navigation } from "./Nav.types";

export const changeColorsLogic = (
  initPos: boolean,
  lastOpen: boolean,
  darkMode: boolean
): boolean => initPos && !lastOpen && darkMode;

export const setScrollEnabled = (enable: boolean) => {
  const body = document.querySelector("body");
  if (body) body.classList.toggle("overflow-hidden", !enable);
  return undefined;
};

/** Redirect to search page when ⌘K is pressed */
export const useSearchShortcut = () => {
  const router = useRouter();

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && e.metaKey) {
        e.preventDefault();
        router.push(routes.search());
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, [router]);
};

export const useOnEscape = (cb: () => void) => {
  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "Escape") cb();
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, [cb]);
};

export const buildDropdowns = (nav: Navigation): DropdownData[] =>
  nav.nodes.map((node) => ({
    id: node.link.name,
    isOpen: false,
    children:
      node.layout === "columns"
        ? node.columns.map((child) => ({
            id: child.id,
            isOpen: false,
            children: [],
          }))
        : [],
  }));

/**
 * It takes a root dropdown and finds each child with isOpen=true recursively.
 * It returns an array of all the opened nodes in parent -> child order
 * @param root - first node which will occur in the resulting array
 */
export const getOpenDropdowns = (root?: DropdownData): DropdownData[] => {
  const getOpenChildren = (cur?: DropdownData): DropdownData[] => {
    const child = cur?.children.find((it) => it.isOpen);
    if (!child) return [];
    return [child, ...getOpenChildren(child)];
  };

  return root?.isOpen ? [root, ...getOpenChildren(root)] : [];
};

/**
 * It traverses dropdowns recursively and returns a Map where key = dropdown ID,
 * value = dropdown
 * @param dropdowns
 */
export const buildDropdownsMap = (
  dropdowns?: DropdownData[]
): Map<string, DropdownData> => {
  const res = new Map<string, DropdownData>();

  const goDown = (cur: DropdownData) => {
    res.set(cur.id, cur);
    cur.children.forEach(goDown);
  };

  if (dropdowns) dropdowns.forEach(goDown);
  return res;
};
