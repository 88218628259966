import Icon from "@atoms/icon/Icon";
import { cn } from "@utils/tailwind";
import { FC, PropsWithChildren, ReactNode } from "react";

export type C2AProps = PropsWithChildren<{
  icon?: ReactNode;
  className?: string;
}>;

export const C2A: FC<C2AProps> = ({ children, icon, className }) => (
  <span
    className={cn(
      "relative animate-spring-container-backward group-hover/button:animate-spring-container-forward",
      className
    )}
  >
    <span className="invisible">{children}</span>

    <span className="absolute top-0 animate-spring-text-backward group-hover/button:animate-spring-text-forward">
      {children}
    </span>

    <span className="absolute top-1/2 inline-flex w-5 animate-spring-icon-backward group-hover/button:animate-spring-icon-forward">
      {icon || <Icon name="ArrowRight" className="size-full" />}
    </span>
  </span>
);
