import Icon from "@atoms/icon/Icon";
import { Loader } from "@atoms/loader/Loader";
import { cn } from "@utils/tailwind";
import { FC } from "react";

export const MobileSubmitButton: FC<{ loading: boolean; show?: boolean }> = ({
  loading,
  show = true,
}) => (
  <button
    type="submit"
    aria-label="Submit"
    className={cn(
      "h-full flex-none px-9 py-4 text-content-primary-on-dark",
      show ? "sm:hidden" : "hidden",
      loading ? "py-0 opacity-40" : "hover:opacity-80"
    )}
    disabled={loading}
  >
    {loading ? (
      <Loader size={4} className="outline-none" />
    ) : (
      <Icon name="ArrowRight" className="h-3" />
    )}
  </button>
);
